import axios from "axios"

import config from "../service/config"


let headers = {
    'Accept': 'application/json, text/plain, */*',
    'Content-Type': 'application/json',
}

let myAxios = axios.create({
    baseURL: config.API_URL,
    headers: headers
})


myAxios.interceptors.request.use(function (config) {
    return config;
}, function(error) {
    return Promise.reject(error);
});

myAxios.interceptors.response.use(function(response) {
    return response;
}, function(error) {
    return Promise.reject(error)
});

const api = {
    abortedleads(endPoint, payload) {
        return new Promise((resolve, reject) => {
            myAxios.post(config.ABORTED_LEADS+'/'+endPoint, payload)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(e => {
                    reject(e)
                })
        })
    },
}

export default api