import React, { useEffect, useState, useRef } from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout/layout"
import Meta from "../../components/addon/meta"
import Schema from "../../components/addon/schema"
import { acceptOnlyNumbers } from "../../service/helper"
import toast from 'react-hot-toast'
import Packege from "../../components/addon/packageCard"
import SimpleReactValidator from "simple-react-validator"
import APIButton from "../../components/addon/button/apiButton";
import API from "../../api/postings"
import UseCustomValidator from "../../service/simpleValidator"
import Thankyou from '../../components/addon/thankYou'

const ExpressHealthCheckup = (props) => {
    const { customMobileValidator } = UseCustomValidator()
    const formValidator = customMobileValidator

    const nodeTemplatePages = props?.data?.allNodeTemplatePages
    const nodePackages = props?.data?.allNodePackages?.nodes
    const [, forceUpdateForRegister] = useState();
    const rawData = nodeTemplatePages?.edges[0]
    const metaTags = rawData.node?.metatag_normalized.length > 0 ? rawData?.node?.metatag_normalized : [];
    const schema = rawData?.node?.relationships?.field_seo_schema ? rawData?.node?.relationships?.field_seo_schema : []
    const data = rawData?.node?.relationships?.components;
    const [btnloader, setBtnLoader] = useState(false)

    const [thankYou, setThankYou] = useState(false)
    
    const bannerImg = typeof (data[0]?.relationships?.bannerImage?.uri?.url) !== "undefined" ? data[0]?.relationships?.bannerImage?.uri?.url?.replace('http://', 'https://') : "/assets/images/patient_care/banner_patient_care.jpg"
    const bannerAlt = typeof (data[0]?.bannerImage?.alt) !== "undefined" && data[0]?.bannerImage?.alt?.length > 0 ? data[0]?.bannerImage?.alt : "Hinduja Hospital"
    const bannerTitle = typeof (data[0]?.title) !== "undefined" && data[0]?.title?.length > 0 ? data[0]?.title : "";
    const howToAvailEHCPackage = data?.length > 2 && data[3]
    const expressHealthCheckAbout = data?.length > 0 && data[1]
    const thankyouScroll = useRef()
    const mobileValidator = customMobileValidator
    const [personalDetailValidator] = useState(useRef(new SimpleReactValidator({
      element: (message, className) => <p className="field_error" > {message} </p>,
      validators: {
          // validateAadhar: {
          //     message: 'The :attribute should be a valid aadhar no.',
          //     rule: (val, params, validator) => {
          //         if(aadharRegex.test(val)) {
          //             return val
          //         } else {
          //             return false
          //         }
          //     },
          //     messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params)),  // optional
          //     required: false  // optional
          // },
      }
  })))

    let [formData, setFormData] = useState({
      webform_id: "express_health_checkup_query",
      email: "",
      phone: "",
      comments: "",
    })

    const onFormDataChange = (event) => {
      const { id, value } = event?.target
      if(id === 'phone') {
          let val = acceptOnlyNumbers(value)
          setFormData({
              ...formData,
              [id]: val
          })
      } else {
          setFormData({
              ...formData,
              [id]: value
          })
      }
    }

    const onSubmit = async () => {
      const formResult = personalDetailValidator.current.allValid()
        const mobileResult = mobileValidator.current.allValid()
        if (formResult === false) {
            personalDetailValidator.current.showMessages()
            forceUpdateForRegister(1)
        }
        
        if (mobileResult === false) {
            mobileValidator.current.showMessages()
            forceUpdateForRegister(2)
        }

      if (formResult === false) {
          formValidator.current.showMessages()
      } 
      if(formResult && mobileResult){
          setBtnLoader(true)
          const response = await API.submitFormData({ ...formData}).catch(err => {
            console.log(err)
            toast.error("Something went wrong")
            })
          if (response) {
            setBtnLoader(false)
              setThankYou(true);
              // resetForm();
              // window.scroll({ top: 0, behavior: "smooth" })
          }
      }
    }

    const resetThankyou = (value) => {
      if(window) window.location.reload();
    }
  
    return (
        <>
            <Layout>
                <Meta
                    files={{
                        js: [],
                        css: [
                            "/assets/css/common.css", 
                            "/assets/css/blog.css", 
                            "/assets/css/accessibility.css",
                            "/assets/css/testimonial.css",
                            "/assets/css/services.css",
                        ],
                    }}
                    tags={metaTags}
                />
                <Schema schema={schema} />
                <main className="innerpage"> 
                    <section className="inner_common_banner">
                        <picture className="banner_img">
                          <img src={bannerImg} alt={bannerAlt} />
                        </picture>
                        <div className="banner_content text_left text_dark">
                          <div className="container">
                            <h1>{bannerTitle}</h1>
                          </div>
                        </div>
                    </section>

                    <section className="section-py">
                      <div className="container">
                          <h3>{expressHealthCheckAbout?.field_title}</h3>
                          <div dangerouslySetInnerHTML={{ __html: expressHealthCheckAbout?.text?.processed || '' }}></div>
                          <p><strong>Why P. D. Hinduja Hospital's Express Health Check Up</strong></p>
                          <div className="section_healthcheckup">
                              <div className="checkup_slider no-slider">
                                  <div className="row">
                                      <div className="col-md-4 slide-col mb-4">
                                          <div className="checkup_card color-blue h-100 no-btns p-4">
                                              <div className="slide">
                                                  <img src="/assets/images/health-checkup/icon-1.svg" alt="Icon" />
                                                  <p className="mb-0 text-left">P. D. Hinduja Hospital Lab, trusted for over 70 years </p>
                                              </div>    
                                          </div>
                                      </div>
                                      <div className="col-md-4 slide-col mb-4">
                                        <div className="checkup_card color-blue h-100 no-btns p-4">
                                            <div className="slide">
                                              <img src="/assets/images/health-checkup/icon-2.svg" alt="Icon" />
                                              <p className="mb-0 text-left">Accuracy and reliability assured</p>
                                            </div>    
                                        </div>
                                    </div>    
                                      
                                      <div className="col-md-4 slide-col mb-4">
                                          <div className="checkup_card color-blue h-100 no-btns p-4">
                                              <div className="slide">
                                              <img src="/assets/images/health-checkup/icon-3.svg" alt="Icon" />
                                                  <p className="mb-0 text-left">CAP and NABL accredited lab</p>
                                              </div>    
                                          </div> 
                                      </div>  
                                      <div className="col-md-4 slide-col mb-4">
                                          <div className="checkup_card color-blue h-100 no-btns p-4">  
                                              <div className="slide">   
                                              <img src="/assets/images/health-checkup/icon-4.svg" alt="Icon" />
                                                  <p className="mb-0 text-left">Free home sample collection</p>
                                              </div>    
                                          </div>
                                      </div>     
                                      <div className="col-md-4 slide-col mb-4">
                                          <div className="checkup_card color-blue h-100 no-btns p-4">
                                              <div className="slide">    
                                              <img src="/assets/images/health-checkup/icon-5.svg" alt="Icon" />
                                                  <p className="mb-0 text-left">Free online consultation with expert Hinduja Hospital Doctors</p>
                                              </div>    
                                          </div>
                                      </div>  
                                      <div class="col-md-4 slide-col mb-4">
                                          <div class="checkup_card color-blue h-100 no-btns p-4">
                                              <div class="slide">     
                                              <img src="/assets/images/health-checkup/icon-6.svg" alt="Icon" />
                                                  <p class="mb-0 text-left">Smart reports to give your health risk assessment</p>
                                              </div>    
                                          </div>
                                      </div>    
                                  </div>
                              </div>  
                          </div>    
                      </div>  
                    </section>

                    <section className="section_bg py-5">
                      <div className="container">
                          <h3 className="pb-3">Packages:</h3>  
                          <div className="row">
                            {
                              nodePackages && nodePackages.length > 0 && nodePackages.map( item => {
                                return(
                                  <Packege data={item} />
                                )
                              })
                            }
                          </div>
                      </div>   
                    </section> 

                    <section className="py-4">
                        <div className="container">
                            <div className="row">
                              <div className="col-md-12 col-lg-7">
                                  <h3>{howToAvailEHCPackage?.field_title}</h3>
                                  <div dangerouslySetInnerHTML={{ __html:howToAvailEHCPackage?.text?.processed || '' }}></div>
                              </div>
                              
                              <div className="col-md-12 col-lg-5">   
                                  <div className="comment_section mt-0">
                                      <div className="row">
                                          <div className="col-md-12">
                                            <div className="comment_header">
                                                <h3>Request a call for appointment</h3>
                                            </div>
                                            {
                                              thankYou ? <Thankyou ref={thankyouScroll} setThankYou={resetThankyou} /> : (
                                                <div className="comment_box form_wrap">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="form-group -animated">
                                                            <label>Email Id <span className="mandatory">*</span></label>
                                                              <input
                                                                  onChange={onFormDataChange}
                                                                  className="form-control"
                                                                  type="text"
                                                                  value={formData.email}
                                                                  id="email"
                                                                  name="email"
                                                              />
                                                              {personalDetailValidator.current.message('email', formData.email, 'required|email')}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="form-group -animated">
                                                              <label>Mobile <span className="mandatory">*</span></label>
                                                              <input
                                                                  onChange={onFormDataChange}
                                                                  className="form-control"
                                                                  type="tel"
                                                                  value={formData.phone}
                                                                  id="phone"
                                                                  name="phone"
                                                              />
                                                              {mobileValidator.current.message('phone', formData.phone, 'required|min:10|max:10|validateMobile')}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="form-group -animated">
                                                            <label>Comments <span className="mandatory">*</span></label>
                                                              <textarea
                                                                  onChange={onFormDataChange}
                                                                  className="form-control"
                                                                  type="text"
                                                                  value={formData.comments}
                                                                  id="comments"
                                                                  name="comments"
                                                              ></textarea>
                                                              {personalDetailValidator.current.message('comments', formData.comments, 'required')}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form_block pl-0">
                                                      <APIButton 
                                                          title={`Continue`} 
                                                          loading={btnloader}  
                                                          onBtnClick={(e) => !btnloader ? onSubmit(e) : {}}  
                                                          className={`btn btn-primary`} 
                                                      />
                                                    </div>  

                                                    <p><strong>For Appointment</strong> : <a href="tel:02267668181"> 022 67668181</a> / <a href="tel:02245108181">022 45108181</a></p>
                                                </div>
                                              )
                                            }
                                          </div>   
                                      </div>      
                                  </div>
                              </div>
                            </div>      
                        </div>
                    </section>
                </main>
            </Layout>
        </>
    )
}
export const query = graphql`
query expressHealthCheckup {
  allNodeTemplatePages(
    filter: {path: {alias: {regex: "/express-health-checkup$/"}}}
  ) {
    edges {
      node {
        id
        title
        path {
          alias
        }
        relationships {
          field_seo_schema {
            field_seo_title
            field_seo_schema_text_area
          }
        }
        metatag_normalized {
          attributes {
            content
            name
            property
          }
        }
        relationships {
          components: field_component_type {
            __typename
            ...acaParagraphBanner
            ...reParagraphTitleAndDescription
          }
        }
      }
    }
  }
  allNodePackages {
    nodes {
      id
      title
      field_price
      path {
        alias
      }
      created(formatString: "MMMM D,YYYY | hh:mm A")
      title
      field_image {
        alt
      }
      relationships {
        image: field_image {
          id
          uri {
            value
            url
          }
        }
      }
    }
  }
}

fragment acaParagraphBanner on paragraph__banner {
  id
  title: field_title
  subtitle: field_subtitle
  bannerImage: field_banner_image {
    alt
  }
  mobileBannerImage: field_mobile_banner {
    alt
  }
  bannerLink: field_banner_link {
    uri
    title
  }
  relationships {
    bannerImage: field_banner_image {
      id
      uri {
        value
        url
      }
    }
    mobileBannerImage: field_mobile_banner {
      id
      uri {
        url
        value
      }
    }
  }
}

fragment reParagraphTitleAndDescription on paragraph__title_and_description {
  id
  field_title
  text: field_description {
    processed
  }
  field_cta_link {
    title
    uri
  }
  field_text {
    processed
  }
}
`
export default ExpressHealthCheckup