import React from "react"

import { Link } from "gatsby"

import ImageComponent from "../../pageSections/imageComponent"
import CardBadge from "../cardBadge"
import ShareButton from "../shareButton"

const PackageCard = props => {
  const { data } = props
  
  const defaultImage = "https://via.placeholder.com/348x233"
 
  return (
    <>
      <div className="col-lg-4 col-md-6 mb-4">
        <div className="card blog_card">
          <div className="img_video_wrapper">
              <img className="lazyload" src={data?.relationships?.image?.uri?.url} alt={data?.field_image?.alt} />
          </div>
          <div className="card-body">
              <h3>{data?.title}</h3>
              <div className="d-flex align-items-center mb-3">   
                <img className="w-auto rounded-0 mr-1" src="/assets/images/package-price-icon.png" alt="Rupees icon" />
                <p><strong>{data?.field_price} /-</strong></p>
              </div>
          </div>
          <div className="card-link">
              <Link
                to={data?.path?.alias}
                className="btn-link"
                aria-label='View more'>
                View more
              </Link>
          </div>
        </div>
      </div>
    </>
  )
}

export default PackageCard
