exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-blogs-all-category-blogs-js": () => import("./../../../src/blogs/allCategoryBlogs.js" /* webpackChunkName: "component---src-blogs-all-category-blogs-js" */),
  "component---src-blogs-blog-js": () => import("./../../../src/blogs/blog.js" /* webpackChunkName: "component---src-blogs-blog-js" */),
  "component---src-doctors-profile-js": () => import("./../../../src/doctors/profile.js" /* webpackChunkName: "component---src-doctors-profile-js" */),
  "component---src-dynamic-pages-dynamic-pages-js": () => import("./../../../src/dynamicPages/dynamicPages.js" /* webpackChunkName: "component---src-dynamic-pages-dynamic-pages-js" */),
  "component---src-events-event-js": () => import("./../../../src/events/event.js" /* webpackChunkName: "component---src-events-event-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-founder-js": () => import("./../../../src/pages/about-founder.js" /* webpackChunkName: "component---src-pages-about-founder-js" */),
  "component---src-pages-about-inspiration-js": () => import("./../../../src/pages/about-inspiration.js" /* webpackChunkName: "component---src-pages-about-inspiration-js" */),
  "component---src-pages-about-our-guiding-principles-js": () => import("./../../../src/pages/about-our-guiding-principles.js" /* webpackChunkName: "component---src-pages-about-our-guiding-principles-js" */),
  "component---src-pages-about-through-the-ages-js": () => import("./../../../src/pages/about-through-the-ages.js" /* webpackChunkName: "component---src-pages-about-through-the-ages-js" */),
  "component---src-pages-academics-js": () => import("./../../../src/pages/academics.js" /* webpackChunkName: "component---src-pages-academics-js" */),
  "component---src-pages-activate-account-js": () => import("./../../../src/pages/activate-account.js" /* webpackChunkName: "component---src-pages-activate-account-js" */),
  "component---src-pages-administrative-terms-conditions-js": () => import("./../../../src/pages/administrative-terms-conditions.js" /* webpackChunkName: "component---src-pages-administrative-terms-conditions-js" */),
  "component---src-pages-admission-form-js": () => import("./../../../src/pages/admission-form.js" /* webpackChunkName: "component---src-pages-admission-form-js" */),
  "component---src-pages-awards-js": () => import("./../../../src/pages/awards.js" /* webpackChunkName: "component---src-pages-awards-js" */),
  "component---src-pages-blog-query-thankyou-js": () => import("./../../../src/pages/blog-query-thankyou.js" /* webpackChunkName: "component---src-pages-blog-query-thankyou-js" */),
  "component---src-pages-bmi-calculator-js": () => import("./../../../src/pages/bmi-calculator.js" /* webpackChunkName: "component---src-pages-bmi-calculator-js" */),
  "component---src-pages-board-of-management-js": () => import("./../../../src/pages/board-of-management.js" /* webpackChunkName: "component---src-pages-board-of-management-js" */),
  "component---src-pages-book-an-appointment-js": () => import("./../../../src/pages/book-an-appointment.js" /* webpackChunkName: "component---src-pages-book-an-appointment-js" */),
  "component---src-pages-care-home-js": () => import("./../../../src/pages/care-home.js" /* webpackChunkName: "component---src-pages-care-home-js" */),
  "component---src-pages-careers-index-js": () => import("./../../../src/pages/careers/index.js" /* webpackChunkName: "component---src-pages-careers-index-js" */),
  "component---src-pages-careers-listing-js": () => import("./../../../src/pages/careers/listing.js" /* webpackChunkName: "component---src-pages-careers-listing-js" */),
  "component---src-pages-careers-postings-js": () => import("./../../../src/pages/careers/postings.js" /* webpackChunkName: "component---src-pages-careers-postings-js" */),
  "component---src-pages-center-of-excellence-js": () => import("./../../../src/pages/center-of-excellence.js" /* webpackChunkName: "component---src-pages-center-of-excellence-js" */),
  "component---src-pages-confirm-pay-js": () => import("./../../../src/pages/confirm-pay.js" /* webpackChunkName: "component---src-pages-confirm-pay-js" */),
  "component---src-pages-contact-us-enquiry-js": () => import("./../../../src/pages/contact-us-enquiry.js" /* webpackChunkName: "component---src-pages-contact-us-enquiry-js" */),
  "component---src-pages-contact-us-helpline-js": () => import("./../../../src/pages/contact-us-helpline.js" /* webpackChunkName: "component---src-pages-contact-us-helpline-js" */),
  "component---src-pages-diseases-and-condition-js": () => import("./../../../src/pages/diseases-and-condition.js" /* webpackChunkName: "component---src-pages-diseases-and-condition-js" */),
  "component---src-pages-doctor-consultation-details-js": () => import("./../../../src/pages/doctor-consultation/details.js" /* webpackChunkName: "component---src-pages-doctor-consultation-details-js" */),
  "component---src-pages-doctor-consultation-index-js": () => import("./../../../src/pages/doctor-consultation/index.js" /* webpackChunkName: "component---src-pages-doctor-consultation-index-js" */),
  "component---src-pages-doctor-consultation-payment-js": () => import("./../../../src/pages/doctor-consultation-payment.js" /* webpackChunkName: "component---src-pages-doctor-consultation-payment-js" */),
  "component---src-pages-e-brochure-js": () => import("./../../../src/pages/e-brochure.js" /* webpackChunkName: "component---src-pages-e-brochure-js" */),
  "component---src-pages-emergency-services-js": () => import("./../../../src/pages/emergency-services.js" /* webpackChunkName: "component---src-pages-emergency-services-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-events-registration-js": () => import("./../../../src/pages/events-registration.js" /* webpackChunkName: "component---src-pages-events-registration-js" */),
  "component---src-pages-express-health-checkup-detail-js": () => import("./../../../src/pages/express-health-checkup/detail.js" /* webpackChunkName: "component---src-pages-express-health-checkup-detail-js" */),
  "component---src-pages-express-health-checkup-index-js": () => import("./../../../src/pages/express-health-checkup/index.js" /* webpackChunkName: "component---src-pages-express-health-checkup-index-js" */),
  "component---src-pages-find-a-doctor-js": () => import("./../../../src/pages/find-a-doctor.js" /* webpackChunkName: "component---src-pages-find-a-doctor-js" */),
  "component---src-pages-help-centre-js": () => import("./../../../src/pages/help-centre.js" /* webpackChunkName: "component---src-pages-help-centre-js" */),
  "component---src-pages-hinduja-ivf-centre-js": () => import("./../../../src/pages/hinduja-ivf-centre.js" /* webpackChunkName: "component---src-pages-hinduja-ivf-centre-js" */),
  "component---src-pages-how-it-works-js": () => import("./../../../src/pages/how-it-works.js" /* webpackChunkName: "component---src-pages-how-it-works-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inpatient-js": () => import("./../../../src/pages/inpatient.js" /* webpackChunkName: "component---src-pages-inpatient-js" */),
  "component---src-pages-international-patient-js": () => import("./../../../src/pages/international-patient.js" /* webpackChunkName: "component---src-pages-international-patient-js" */),
  "component---src-pages-knee-implant-pricing-js": () => import("./../../../src/pages/knee-implant-pricing.js" /* webpackChunkName: "component---src-pages-knee-implant-pricing-js" */),
  "component---src-pages-legal-terms-conditions-js": () => import("./../../../src/pages/legal-terms-conditions.js" /* webpackChunkName: "component---src-pages-legal-terms-conditions-js" */),
  "component---src-pages-locate-us-js": () => import("./../../../src/pages/locate-us.js" /* webpackChunkName: "component---src-pages-locate-us-js" */),
  "component---src-pages-medical-insurance-tpa-js": () => import("./../../../src/pages/medical-insurance-tpa.js" /* webpackChunkName: "component---src-pages-medical-insurance-tpa-js" */),
  "component---src-pages-medical-report-details-js": () => import("./../../../src/pages/medical-report/details.js" /* webpackChunkName: "component---src-pages-medical-report-details-js" */),
  "component---src-pages-medical-report-index-js": () => import("./../../../src/pages/medical-report/index.js" /* webpackChunkName: "component---src-pages-medical-report-index-js" */),
  "component---src-pages-my-reports-upload-js": () => import("./../../../src/pages/my-reports-upload.js" /* webpackChunkName: "component---src-pages-my-reports-upload-js" */),
  "component---src-pages-online-interim-bills-js": () => import("./../../../src/pages/online-interim-bills.js" /* webpackChunkName: "component---src-pages-online-interim-bills-js" */),
  "component---src-pages-online-services-js": () => import("./../../../src/pages/online-services.js" /* webpackChunkName: "component---src-pages-online-services-js" */),
  "component---src-pages-opd-deposit-details-js": () => import("./../../../src/pages/opd-deposit/details.js" /* webpackChunkName: "component---src-pages-opd-deposit-details-js" */),
  "component---src-pages-opd-deposit-index-js": () => import("./../../../src/pages/opd-deposit/index.js" /* webpackChunkName: "component---src-pages-opd-deposit-index-js" */),
  "component---src-pages-our-accreditations-js": () => import("./../../../src/pages/our-accreditations.js" /* webpackChunkName: "component---src-pages-our-accreditations-js" */),
  "component---src-pages-out-patient-js": () => import("./../../../src/pages/out-patient.js" /* webpackChunkName: "component---src-pages-out-patient-js" */),
  "component---src-pages-patient-care-js": () => import("./../../../src/pages/patient-care.js" /* webpackChunkName: "component---src-pages-patient-care-js" */),
  "component---src-pages-pay-ipd-bill-details-js": () => import("./../../../src/pages/pay-ipd-bill/details.js" /* webpackChunkName: "component---src-pages-pay-ipd-bill-details-js" */),
  "component---src-pages-pay-ipd-bill-index-js": () => import("./../../../src/pages/pay-ipd-bill/index.js" /* webpackChunkName: "component---src-pages-pay-ipd-bill-index-js" */),
  "component---src-pages-pay-reservation-deposit-details-js": () => import("./../../../src/pages/pay-reservation-deposit/details.js" /* webpackChunkName: "component---src-pages-pay-reservation-deposit-details-js" */),
  "component---src-pages-pay-reservation-deposit-index-js": () => import("./../../../src/pages/pay-reservation-deposit/index.js" /* webpackChunkName: "component---src-pages-pay-reservation-deposit-index-js" */),
  "component---src-pages-payment-failed-js": () => import("./../../../src/pages/payment-failed.js" /* webpackChunkName: "component---src-pages-payment-failed-js" */),
  "component---src-pages-payment-js": () => import("./../../../src/pages/payment.js" /* webpackChunkName: "component---src-pages-payment-js" */),
  "component---src-pages-philanthrophy-js": () => import("./../../../src/pages/philanthrophy.js" /* webpackChunkName: "component---src-pages-philanthrophy-js" */),
  "component---src-pages-poison-centre-js": () => import("./../../../src/pages/poison-centre.js" /* webpackChunkName: "component---src-pages-poison-centre-js" */),
  "component---src-pages-prescription-upload-js": () => import("./../../../src/pages/prescription-upload.js" /* webpackChunkName: "component---src-pages-prescription-upload-js" */),
  "component---src-pages-press-release-detail-js": () => import("./../../../src/pages/press-release/detail.js" /* webpackChunkName: "component---src-pages-press-release-detail-js" */),
  "component---src-pages-press-release-index-js": () => import("./../../../src/pages/press-release/index.js" /* webpackChunkName: "component---src-pages-press-release-index-js" */),
  "component---src-pages-print-voucher-js": () => import("./../../../src/pages/print-voucher.js" /* webpackChunkName: "component---src-pages-print-voucher-js" */),
  "component---src-pages-refer-a-patient-js": () => import("./../../../src/pages/refer-a-patient.js" /* webpackChunkName: "component---src-pages-refer-a-patient-js" */),
  "component---src-pages-research-js": () => import("./../../../src/pages/research.js" /* webpackChunkName: "component---src-pages-research-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-search-speciality-js": () => import("./../../../src/pages/search-speciality.js" /* webpackChunkName: "component---src-pages-search-speciality-js" */),
  "component---src-pages-secure-your-health-js": () => import("./../../../src/pages/secure-your-health.js" /* webpackChunkName: "component---src-pages-secure-your-health-js" */),
  "component---src-pages-service-detail-allergy-js": () => import("./../../../src/pages/service-detail-allergy.js" /* webpackChunkName: "component---src-pages-service-detail-allergy-js" */),
  "component---src-pages-short-stay-service-js": () => import("./../../../src/pages/short-stay-service.js" /* webpackChunkName: "component---src-pages-short-stay-service-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-stent-pricing-js": () => import("./../../../src/pages/stent-pricing.js" /* webpackChunkName: "component---src-pages-stent-pricing-js" */),
  "component---src-pages-student-testimonial-js": () => import("./../../../src/pages/student-testimonial.js" /* webpackChunkName: "component---src-pages-student-testimonial-js" */),
  "component---src-pages-teleconsultation-js": () => import("./../../../src/pages/teleconsultation.js" /* webpackChunkName: "component---src-pages-teleconsultation-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-testimonial-js": () => import("./../../../src/pages/testimonial.js" /* webpackChunkName: "component---src-pages-testimonial-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-user-js": () => import("./../../../src/pages/user.js" /* webpackChunkName: "component---src-pages-user-js" */),
  "component---src-pages-whats-new-at-p-d-hinduja-hospital-js": () => import("./../../../src/pages/whats-new-at-p-d-hinduja-hospital.js" /* webpackChunkName: "component---src-pages-whats-new-at-p-d-hinduja-hospital-js" */),
  "component---src-speciality-speciality-listing-js": () => import("./../../../src/speciality/speciality-listing.js" /* webpackChunkName: "component---src-speciality-speciality-listing-js" */)
}

