import React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout/layout"
import Meta from "../../components/addon/meta"
import Schema from "../../components/addon/schema"
import CustomLink from '../../components/addon/Link'

const ExpressHealthCheckupDetail = ({ data: { allNodePackages: { nodes } } }) => {
    const rawData = nodes[0]
    const metaTags = rawData?.metatags.length > 0 ? rawData?.metatags : [];
    const schema = rawData?.node?.relationships?.field_seo_schema ? rawData?.node?.relationships?.field_seo_schema : []
    console.log('amitab====================',rawData)
    const tableContent = rawData?.relationships?.field_package_inclusion

    return (
        <>
            <Layout>
                <Meta
                    files={{
                        js: [],
                        css: ["/assets/css/blog.css", "/assets/css/accessibility.css", "/assets/css/services.css"],
                    }}
                    tags={metaTags}
                />
                <Schema schema={schema} />
                <main className="innerpage">
                  <section class="">
                      <div class="container">
                        <div class="row">
                            <div class="col-md-12">
                              <div class="blog_detail_head">
                              <div class="back-btn-wrap">
                                 <a href="/express-health-checkup">Back</a>
                              </div>
                                  <div class="heading_with_likes">
                                    <h1>{rawData?.title}</h1>
                                  </div>
                              </div>
                              <div class="blog_content">
                                  <div class="blog_body">
                                    <div class="blog_header">Package Price : ₹ {rawData?.field_price} /-</div>
                                    <div class="row justify-content-center">
                                        <div class="col-md-12 col-lg-6">
                                          {tableContent && tableContent.length > 0 && <div class="table-price-wrap table-price-wrap-orange express-health-checkup-table pb-3">
                                              <table border="0" cellpadding="0" cellspacing="0" width="100%">
                                                <thead>
                                                    <tr>
                                                      <th colspan="2"><strong>{rawData?.title}:</strong></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                  {
                                                    tableContent.map(item => {
                                                      return(
                                                        <tr>
                                                          <td align="left" valign="middle">
                                                            <table border="0" cellpadding="0" cellspacing="0" width="100%">
                                                                <tbody>
                                                                    {
                                                                      item?.relationships?.field_package_test.map(eq => {
                                                                        return <tr><td align="left" valign="middle">{eq?.field_title}</td></tr>
                                                                      })
                                                                    }
                                                                </tbody>
                                                            </table>
                                                          </td>
                                                          <td align="center" valign="middle">{item?.field_title}</td>
                                                      </tr>
                                                      )
                                                    })
                                                  }
                                                </tbody>
                                              </table>
                                          </div>}
                                        </div>   
                                    </div>   
                                  </div>
                              </div>
                            </div>   
                        </div>
                      </div>
                  </section>
                </main>
            </Layout>
        </>
    )
}

export const query = graphql`
query($packageId: String) {
  allNodePackages(filter: {id: {eq: $packageId}}) {
    nodes {
      id
      path {
        alias
      }
      metatags: metatag_normalized {
        attributes {
          content
          name
        }
      }
      created(formatString: "MMMM D,YYYY | hh:mm A")
      title
      field_price
      field_image {
        alt
      }
      relationships {
        image: field_image {
          id
          uri {
            value
            url
          }
        }
        image_mobile: field_image {
          id
          uri {
            value
            url
          }
        }
        field_package_inclusion: field_package_inclusion{
            field_title,
            relationships {
              field_package_test {
                field_title
              }
            }
          }
      }
    }
  }
}
`

export default ExpressHealthCheckupDetail;